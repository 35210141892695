// Footer
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
.site-footer {
	padding: 3rem 0;
	background: $dark-grey;
	// opacity: 0.7;

	.container {}
}

.footer__copyright {
	color: darken(#fff, 50%);
	border-top: 1px solid #535353;
	margin-top: 0;
}

.site-credit {
	display: block;
	padding-top: 1rem;

	> a {
		color: inherit;
		transition: all .2s;

		&:hover {
			color: #fff;
		}
	}
}

.footer__nav {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	padding-bottom: 3rem;

	> a {
		width: calc(50% - 1rem);
		padding: 1rem 0 1rem;
		font-size: 1.333rem;
		line-height: 2rem;
		@include align-to-baseline( 1.333rem, 2rem, $worksans-cap-height );

		&:nth-child(1) {
			order: 1;
		}

		&:nth-child(4) {
			order: 2;
			margin-left: 2rem;
		}

		&:nth-child(2) {
			order: 3;
		}

		&:nth-child(5) {
			order: 4;
			margin-left: 2rem;
		}

		&:nth-child(3) {
			order: 5;
		}

		&:nth-child(6) {
			order: 6;
			margin-left: 2rem;
		}
	}
}

.footer__extra {
	display: flex;
	flex-flow: column nowrap;
	// justify-content: flex-start;
	// align-items: flex-start;
}

.footer__extra .button {
	order: 2;
	font-size: 1.333rem;
	line-height: 1;
	margin-top: calc(1rem + 1px);
}

.footer__social-links {
	order: 1;
	padding-bottom: 2rem;

	> a {
		margin-right: 2.5rem;
		text-decoration: none;
		opacity: 1;
		transition: opacity 0.2s;

		> svg {
			transform: scale(1.3);
		}

		&:hover {
			opacity: 0.5;
		}
	}
}

.footer__logo {
	text-align: center;
	padding: 5rem 0;

	svg {
		transform: scale(1.2);
	}
}

@include breakpoint($tablet-landscape) {
	.site-footer {
		padding: 4rem 0 3rem;

		.container {
			display: grid;
			grid-template-rows: auto 1fr;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 3rem;
		}
	}

	.footer__copyright {
		grid-column: 1/-1;
	}

	.site-credit {
		float: right;
		padding-top: 0;
	}

	.footer__nav {
		padding-bottom: 6rem;

		> a {
			width: calc(50% - 1.5rem);

			&:nth-child(4) {
				margin-left: 3rem;
			}

			&:nth-child(5) {
				margin-left: 3rem;
			}

			&:nth-child(6) {
				margin-left: 3rem;
			}
		}
	}

	.footer__extra {
		display: block;
	}

	.footer__social-links {
		padding-top: 5rem;

		> a {
			margin-right: 1rem;

			> svg {
				transform: scale(1);
			}
		}
	}

	.footer__logo {
		text-align: right;
		padding: 1rem 0 0;

		svg {
			transform: scale(1);
		}
	}
}

// Footer Sponsors
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
.footer-sponsors {
	padding: 4rem 0 0;
	background: $dark-grey;
	color: #fff;

	> .container {
		border-bottom: 1px solid #535353;
	}

	a {
		display: none;
	}
}

.footer-sponsors__list {
	clear: both;
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 2rem;
	list-style: none;
	padding: 3rem 0;
	margin: 0;

	> li {
		height: 0;
		padding-bottom: 100%;
		border: 1px solid #535353;
		border-radius: 50%;

		&:nth-child(5) {
			display: none;
		}
	}
}

@include breakpoint($desktop) {
	.footer-sponsors {
		padding: 6rem 3rem 0 3rem;

		h4 {
			float: left;
		}

		a {
			display: block;
			float: right;
			font-size: 1.333rem;
			line-height: 2rem;
			@include align-to-baseline( 1.333rem, 2rem, $worksans-cap-height );
		}
	}

	.footer-sponsors__list {
		clear: both;
		display: grid;
		grid-template: auto / 1fr 1fr 1fr 1fr 1fr;
		grid-column-gap: 6rem;
		padding: 4rem 0 4rem;

		> li:nth-child(5) {
			display: initial;
		}
	}
}

.programs-page {
	.site-nav__link.active {
		border-color: $blue;
	}

	.page-banner {
		background-color: $blue;

		&::after {
			background-color: $blue;
		}
	}
}

.other-program {
	padding-bottom: 3rem;
	background-color: $blue;

	.button {
		margin-top: 3rem;
	}

}

@include breakpoint($tablet-landscape) {
	.other-program {
		padding-bottom: 8rem;

		.button {
			margin-top: 4rem;
		}
	}
}

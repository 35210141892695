// BG / Text Colors / Baseline Grid
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
body {
	color: $dark-grey;
	// background-color: $dark-grey;

	// show baseline
	// background-image: linear-gradient(rgba(255, 0, 0, 0.25) 1px, transparent 1px);
	// background-size: 1px $baseline;
}

// Page layout
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
.content {
	padding-top: 8rem;
	// background-color: #fff;

	@include breakpoint($tablet-landscape) {
		padding-top: 10rem;
	}
}

.container {
	width: 92%;
	margin: 0 auto;

	@include breakpoint($tablet-landscape) {
		width: 960px;
	}

	@include breakpoint($desktop) {
		width: 1104px;
	}

	@include breakpoint($desktop-large) {
		width: 1260px;
	}
}

.page-section {
	padding: 4rem 0;
}

.page-section-title {
	padding-bottom: 1rem;
}

.page-section--2col {
	> .container > div:first-of-type {
		margin-bottom: 4rem;
	}

	.page-section__image {
		background: $light-grey;
		height: 0;
		padding-bottom: 66.67%;
	}
}

.image-cover {
	position: relative;

	> img {
		width: 100%;
		height: 100%;
		position: absolute;
		object-fit: cover;
		object-position: center;
		border: none;
		outline-style: none;
	}
}

@include breakpoint($tablet-landscape) {
	.page-section {
		padding: 6rem 0 8rem;
	}

	.page-section-title {
		padding-bottom: 3rem;
	}

	.page-section--2col {
		> .container {
			display: grid;
			grid-template: auto / 1fr 1fr;
			grid-column-gap: 3rem;

			> div:first-of-type {
				margin-bottom: 0;
			}
		}

		.page-section__image {
			height: 100%;
			min-height: calc(((960px - 3rem) / 2) * 0.6667);
			padding: 0;
		}
	}
}

@include breakpoint($desktop) {
	.page-section--2col {
		.page-section__image {
			min-height: calc(((1104px - 3rem) / 2) * 0.6667);
		}
	}
}

@include breakpoint($desktop-large) {
	.page-section--2col {
		.page-section__image {
			min-height: calc(((1260px - 3rem) / 2) * 0.6667);
		}
	}
}

// Header
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
.site-header {
	position: fixed;
	width: 100%;
	background-color: #fff;
	z-index: 99999;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.0);
	transition: all 0.2s ease-out;
}

.site-header > .container {
	height: 8rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: height 0.2s ease-out;
}

.site-header.scrolled {
	background-color: #fff !important;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

	.container {
		height: 6rem;
	}

	.site-nav__link {
		margin-top: 0;
	}
}

.site-header.scrolled.mobile-open {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.0);
}

.logo > a {
	width: 75%;
	display: block;
	text-indent: -100%;
	position: relative;
	transition: width 0.2s ease-out;

	> svg {
		display: block;
		width: 100%;
	}

	> span {
		display: block;
		position: absolute;
		clip: rect(1px, 1px, 1px, 1px);
		padding: 0;
		border: 0;
		height: 1px;
		width: 1px;
		overflow: hidden;
	}
}

.site-header.scrolled {
	.logo > a {
		width: 60%;
	}
}

.site-title {
	position: absolute;
	visibility: hidden;
}

.site-nav {
	display: none;
}

.mobile-ham {
	// float: right;

	button {
		height: 23px;
		width: 2.5rem;
		outline: none;
		border: none;
		padding: 0;
		margin: 0;
		background: transparent;
	}

	button > div {
		position: relative;
		width: 100%;
		height: 3px;
		background-color: #000;
		transition: background-color .2s ease-in-out;

		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #000;
			content: '';
			transform: rotate(0);
			transition: transform .2s ease-in-out, top .2s ease-in-out;
		}

		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #000;
			content: '';
			transform: rotate(0);
			transition: transform .2s ease-in-out, bottom .2s ease-in-out;
		}
	}

	&.active button > div {
		position: relative;
		width: 100%;
		height: 3px;
		background-color: transparent;

		&::before {
			top: 0px;
			transform: rotate(45deg);
		}

		&::after {
			bottom: 0px;
			transform: rotate(-45deg);
		}
	}
}

.site-nav__link {
	color: $dark-grey;
	transition: all 0.3s ease-out;

	&.active {
		border-color: $dark-grey;
	}
}

.mobile-nav {
	background: #fff;
	position: fixed;
	visibility: hidden;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	padding-top: 10rem;
	opacity: 0;
	transition: opacity .3s ease-in-out, visibility .3s ease-in-out;

	&.open {
		opacity: 1;
		visibility: visible;
	}
}

.mobile-nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.mobile-nav li {
	padding: 0;
	margin: 0;
}

.mobile-nav.open li {
	opacity: 0;
	animation: mobileNavIn 0.3s ease-out both;

	@for $navItem from 1 through 5 {
		&:nth-of-type(#{$navItem}) {
			$delay: $navItem * 0.025 + 0.1;
			animation-delay: #{$delay}s;
		}
	}
}

@keyframes mobileNavIn {
	0% {opacity: 0; transform: translateY(-200%);}
	100% {opacity: 1; transform: translateY(0);}
}

.mobile-nav .site-nav__link {
	font-size: 2rem;
	line-height: 4rem;
	letter-spacing: -0.33px;
	@include align-to-baseline( 2rem, 4rem, $worksans-cap-height );
}

@include breakpoint($tablet-portrait) {
	.site-header > .container {
		display: grid;
		grid-template: auto / 1fr 2fr;
		grid-column-gap: 3rem;
		height: 8rem;
		transition: height 0.2s ease-out;
	}

	.site-header.scrolled {
		background-color: #fff !important;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

		.container {
			height: 6rem;
		}

		.site-nav__link {
			margin-top: 0;
		}
	}

	.logo {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
	}

	.logo > a {
		width: 90%;
	}

	.site-header.scrolled {
		.logo > a {
			width: 70%;
		}
	}

	.site-nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
	}

	.mobile-ham {
		display: none;
	}

	.mobile-menu {
		display: none;
	}

}

@include breakpoint($tablet-landscape) {
	.site-header > .container {
		grid-template: auto / 1fr 1fr;
		height: 10rem;
	}

	.logo > a {
		width: 50%;
	}

	.site-header.scrolled {
		.logo > a {
			width: 35%;
		}
	}
}

@include breakpoint($desktop-large) {
	.logo > a {
		width: 45%;
	}
}

// Page Banner
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
.page-banner {
	position: relative;
	height: auto;
	padding-top: 4rem;
	background: #efefef;
	// opacity: 0.5;
	padding-bottom: 3rem;
	z-index: 1;

	&::after {
		content: '';
		position: absolute;
		display: block;
		background: #efefef;
		width: 100%;
		top: 100%;
		height: 17.25rem;
		z-index: -1;
	}
}

.page-description {
	padding-top: 1rem;
}

@include breakpoint($tablet-portrait) {
	.page-banner {
		&::after {
			height: 30rem;
		}
	}
}

@include breakpoint($tablet-landscape) {
	.page-banner {
		padding-top: 7rem;
		padding-bottom: 8rem;
		background: #efefef;

		&::after {
			height: 24rem;
		}

		.container {
			display: grid;
			grid-template-rows: auto;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 3rem;
		}
	}

	.page-description {
		padding-top: 1rem;
	}
}

@include breakpoint($desktop) {
	.page-banner {
		padding-top: 9rem;
	}
}

// Page Banner
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
// .page-banner {
// 	height: 40rem;
// 	padding-top: 4rem;
// 	background: #efefef;
// 	// opacity: 0.5;
// }
//
// .page-description {
// 	padding-top: 1rem;
// }
//
// .page-banner + section {
// 	margin-top: -23rem;
// }
//
// @include breakpoint($tablet-landscape) {
// 	.page-banner {
// 		height: 44rem;
// 		padding-top: 7rem;
// 		background: #efefef;
//
// 		.container {
// 			display: grid;
// 			grid-template-rows: auto;
// 			grid-template-columns: 1fr 1fr;
// 			grid-column-gap: 36px;
// 		}
// 	}
//
// 	.page-description {
// 		padding-top: 1rem;
// 	}
// }
//
// @include breakpoint($desktop) {
// 	.page-banner {
// 		height: 44rem;
// 		padding-top: 9rem;
// 		background: #efefef;
//
// 		.container {
// 			display: grid;
// 			grid-template-rows: auto;
// 			grid-template-columns: 1fr 1fr;
// 			grid-column-gap: 36px;
// 		}
// 	}
//
// 	.page-description {
// 		padding-top: 1rem;
// 	}
// }

// Hero
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
.hero {
	position: relative;
	width: 100%;
	height: auto;
	z-index: 9;
}

.hero__image {
	width: 100%;
	// height: 16rem;
	height: 0;
	padding-bottom: 60%;
	background-color: $light-grey;
	background-size: cover;
	background-position: center;
	z-index: -1;
}

.hero__card {
	position: relative;
	z-index: 99;
	color: #fff;
	background-color: $dark-grey;
	transition: all 0.2s ease-out;

	> a {
		display: block;
		color: inherit;
		text-decoration: none;
		height: 100%;
		padding: 1.5rem;
	}

	&:hover {
		background-color: lighten($dark-grey, 8%);

		.hero__cta {
			transform: translateX(0.5rem);
		}
	}

	.hero__cta {
		padding: 1rem 0;
		transition: all 0.2s ease-out;
	}
}

@include breakpoint($tablet-landscape) {
	.hero {
		height: 42rem;
		grid-column: 1/-1;
	}

	.hero__image {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: auto;
		width: auto;
		padding: 0;
	}
}

// Page Imports
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
/* stylelint-disable */
@import 				"footer",
						"panels",
						"programs",
						"support",
						"about",
						"front",
						"events",
						"news",
						"news-detail",
						"event-detail",
						"archive-list";
/* stylelint-enable */

.archive {
	padding: 4rem 0 2rem;
}

.archive-list {
	list-style: none;
	padding: 0;
	margin: 3rem 0 0 0;
}

.archive-item {
	background-color: transparent;
	color: $dark-grey;
	transition: all .3s ease-out;

	&:hover {
		background-color: rgba($dark-grey, 0.05);
	}
}

@supports (mix-blend-mode: multiply) {
	.archive-item {
		mix-blend-mode: multiply;

		&:hover {
			background-color: lighten($dark-grey, 80%);
		}
	}
}

.archive-item a {
	display: grid;
	grid-template: auto auto / 1fr 3fr;
	grid-column-gap: 3rem;
	border-top: 1px solid rgba(0, 0, 0, 0.5);
	padding: 0 0 1rem;
	padding-bottom: calc(2rem + 1px);
	text-decoration: none;
	color: inherit;
}

.archive-item__title {
	font-weight: 400;
}

.archive-item__view-details {
	grid-column: 2 / span 1;
	text-align: left;
	transform: translateX(0);
	transition: transform 0.3s ease-out;
}

.archive-item:hover .archive-item__view-details {
	transform: translateX(1rem);
}

@include breakpoint($tablet-portrait) {
	.archive {
		padding: 6rem 0;
	}

	.archive-list {
		margin: 4rem 0 0 0;
	}

	.archive-item a {
		grid-template: auto / 1fr 2fr 1fr;
		padding: 2rem 0;
	}

	.archive-item__date {
		padding-left: 1rem;
	}

	.archive-item__view-details {
		grid-column: 3 / span 1;
		text-align: right;
		padding-right: 2rem;
	}
}

.events-page--secondary {
	background-color: #f8f8f8;
}

.event-info-sidebar {
	border-top: 1px solid lighten(#000, 80%);
	margin-top: 4rem;
	padding-top: 1rem;
}

.button--tickets + p {
	padding-top: 2rem;
}

@include breakpoint($tablet-portrait) {
	.article--event > .container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 3rem;
	}

	@include breakpoint(max-width $tablet-landscape - 1) {
		.article--event {
			.article-header,
			.article-lead-image {
				grid-column: 1 / span 2;
			}
		}
	}

	.event-info-sidebar {
		position: relative;
		grid-column: 2 / span 1;
		padding-bottom: 2rem;
		border: none;
		margin-top: 0;
		padding-top: 0;

		&::before {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 1px;
			content: '';
			background-color: lighten(#000, 80%);
			left: -1.5rem;
		}
	}
}

@include breakpoint($tablet-landscape) {
	.article--event > .container {
		grid-template-columns: 7fr 1fr 4fr;
	}

	.event-info-sidebar {
		grid-column: 3 / span 1;
	}
}

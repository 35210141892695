.news-page {
	.site-nav__link.active {
		border-color: $yellow;
	}

	.page-banner {
		background-color: $yellow;

		&::after {
			background-color: $yellow;
		}
	}
}

.hero--news {
	.category {
		margin-top: 0;
		text-transform: uppercase;
		text-decoration: underline;
	}

	h3 + p {
		padding-top: 1rem;
	}

	.hero__cta {
		padding: 2rem 0 1rem;
	}
}

.news-cards {
	padding: 1rem 0;
}

.news-card {
	position: relative;
	padding: 1rem 0 2rem;
	border-top: 1px solid lighten(#000, 80%);
}

.news-card__link {
	display: block;
	color: inherit;
	text-decoration: none;
}

.news-card.with-image .news-card__link {
	display: grid;
	grid-template: auto / 1fr 2fr;
	grid-column-gap: 1.5rem;
}

.news-card__image {
	width: 100%;
	height: 0;
	padding-bottom: 100%;
	background-color: $light-grey;
	background-size: cover;
	background-position: center;
	opacity: 1;
	transition: opacity 0.3s ease-out;
}

.news-card:hover .news-card__image {
	opacity: 0.65;
}

.news-card__category {
	text-transform: uppercase;
	text-decoration: underline;
	font-size: 1.333rem;
	display: initial;
	padding: 0;
}

.news-card__hed + .news-card__cta {
	padding-top: 2rem;
}

.news-card:hover .news-card__hed {
	text-decoration: underline;
}

.news-card__dek {
	padding-top: 0.5rem;
}

.news-card__cta {
	transform: translateX(0);
	transition: all .3s ease-out;
}

.news-card:hover .news-card__cta {
	transform: translateX(0.5rem);
}

.archive--news {
	background-color: $yellow;
}


@include breakpoint($tablet-portrait) {
	.news-cards {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 3rem;
		padding: 3rem 0;
	}

	.news-card {
		border: none;
		padding: 0 0 3rem;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			width: 1px;
			background: lighten(#000, 80%);
			left: -1.5rem;
		}

		&:nth-child(2n+1) {
			&::before {
				display: none;
			}

			&::after {
				content: '';
				display: block;
				position: absolute;
				top: -1.5rem;
				left: 0;
				width: calc(200% + 3rem);
				height: 1px;
				background: lighten(#000, 80%);
			}
		}

		&.with-image .news-card__link {
			display: block;
		}
	}

	.news-card__image {
		padding-bottom: 66.67%;
		margin-bottom: 1rem;
	}
}


@include breakpoint($tablet-landscape) {
	.hero--news {
		.hero__image {
			left: calc(33.333% - 0.75rem);
		}

		.hero__card {
			position: absolute;
			top: 0rem;
			left: 0rem;
			bottom: 0rem;
			right: calc(66.667% + 0.75rem);

			> a {
				display: flex;
				flex-flow: row wrap;
				height: 100%;
				padding: 1.5rem;
			}
		}

		.hero__cta {
			align-self: flex-end;
		}
	}

	.latest-news {
		// margin-top: -24rem;
	}

	.news-cards {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 3rem;
		padding: 3rem 0;
	}

	.news-card {
		&:nth-child(2n+1) {
			&::before {
				display: block;
			}

			&::after {
				display: none;
			}
		}

		&:nth-child(3n+1) {
			&::before {
				display: none;
			}

			&::after {
				content: '';
				display: block;
				position: absolute;
				top: -1.5rem;
				left: 0;
				width: calc(300% + 6rem);
				height: 1px;
				background: lighten(#000, 80%);
			}
		}
	}

	.news-card__hed {
		padding-top: 0.5rem;
	}

	.news-card:hover .news-card__hed {
		text-decoration: underline;
	}

	.news-card__category + .news-card__hed {
		padding-top: 0.5rem;
	}
}

.panels {
	position: relative;
	padding-bottom: 3rem;
	z-index: 2;
}

.panel {
	padding: 3rem 1.5rem 1.5rem 1.5rem;
	color: #fff;
	background: $dark-grey;
	margin-bottom: 1.5rem;

	&:not(.wide) {
		padding-bottom: 3rem;
	}
}

.panel__secondary {
	margin-top: 4rem;
}

.panel__title + p {
	padding-top: 1rem;
}

.panel__footnote {
	@extend .typo--small;
	display: block;
	color: darken(#fff, 50%);
	padding-top: 2rem;
}

.panel__button {
	margin-top: 3rem;
	width: 100%;
}

.panel__list--single {
	list-style: none;
	padding: 0;
	margin: 3rem 0 0 0;
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 1.5rem;

	li {
		padding: 1rem 0;
		margin-top: -1px;
		border-top: 1px solid #535353;
		border-bottom: 1px solid #535353;

		> p {
			margin: 0;
		}
	}
}

.panel__image {
	width: 100%;
	height: 0;
	padding-bottom: 66.67%;
	background-color: $light-grey;
	background-size: cover;
	background-position: center;
}

.panel__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.panel__list-item {
	padding: 1rem 0 2rem;
	border-top: 1px solid rgba(255, 255, 255, 0.2);

	> h4 {
		margin-top: 0;
	}
}

.panel__table {
	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0;
}

.panel__table-row {
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	overflow: hidden;
	clear: both;

	> p {
		padding: 0;
		margin: 0;
	}

	> p:nth-child(1) {
		font-weight: bold;
	}
}


@include breakpoint($tablet-portrait) {
	.panel {
		padding: 3rem;
	}

	.panel__button {
		width: auto;
	}

	.panel__list--single {
		grid-template-columns: 1fr 1fr;
	}
}


@include breakpoint($tablet-landscape) {
	.panels {
		// margin-top: -24rem;
	}

	.panels > .container {
		display: grid;
		grid-gap: 1.5rem;
		grid-template-columns: 1fr 1fr;
	}

	.panel {
		padding: 3rem;
		margin-bottom: 0;

		&.wide {
			grid-column: span 2;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 3rem;

			&.swap {
				.panel__primary {
					order: 2;
				}

				.panel__secondary {
					order: 1;
				}
			}
		}
	}

	.panel__title + p {
		padding-top: 3rem;
	}

	.panel__secondary {
		margin-top: 0;
	}

	.panel__image {
		height: 100%;
		min-height: calc(((960px - 9rem) / 2) * 0.6667);
		padding: 0;
	}

	// .panel__list-item {
	// 	&:last-of-type {
	// 		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	// 	}
	// }

	.panel__table-row {
		&:last-of-type {
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		}

		> p:nth-child(1) {
			font-weight: 400;
			float: left;
		}

		> p:nth-child(2) {
			float: right;
		}
	}
}

@include breakpoint($desktop) {
	.panel__image {
		min-height: calc(((1104px - 9rem) / 2) * 0.6667);
	}
}

@include breakpoint($desktop-large) {
	.panel__image {
		min-height: calc(((1260px - 9rem) / 2) * 0.6667);
	}
}

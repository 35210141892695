@charset "utf-8";
/* stylelint-disable */

// Import Libs
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
@import					"normalize-css",
						"modularscale-sass",
						"responsive-baseline",
						"breakpoint-sass";

//
// Breakpoints
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
$phone				:	375px;
$phone-large		:	580px;
$tablet-portrait	:	768px;
$tablet-landscape	:	1024px;
$desktop			:	1280px;
$desktop-large		:	1600px;
$desktop-hd			:	$desktop-large; //1920px

//
// Colors
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
$dark-grey			:	#282828;
$light-grey			:	#F0F0F0;
$brown				:	#E0AE87;
$blue				:	#7AC8F5;
$green				:	#94DFBA;
$yellow				:	#FCBD31;
$red				:	#E6746A;

//
// Typography
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
$volkhov			:	'Volkhov', Georgia, Times, serif;
$worksans			:	'Work Sans', Helvetica, Arial, sans-serif;
$baseline			:   1rem;
$base-line-height	:   $baseline * 2;

// Type Scale
$modularscale: (
	base			:	1rem,
	ratio			:	$minor-third,
);

// Fluid rem
$fluid-rem: (
	$phone: (
		base		:	10px,
	),
	// $tablet-portrait: (
	// 	base		:	9px,
	// ),
	$tablet-landscape: (
		base		:	12px,
	),
	// $desktop-large: (
	// 	base		: 	12px,
	// )
);

//
// Grid
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––


//
// Import Partials
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
@import 				"base",
        				"layout";

/* stylelint-enable */

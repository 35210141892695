.events-page {
	.site-nav__link.active {
		border-color: $green;
	}

	.page-banner {
		background-color: $green;

		&::after {
			background-color: $green;
		}
	}
}

.archive--events {
	background-color: $green;
}

.upcoming-events {}

.event-cards {
	padding: 1.5rem 0 3rem;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1.5rem;
}

.event-card {
	background-color: $dark-grey;
	color: #fff;
	transition: all 0.2s ease-out;

	&:hover {
		background-color: lighten($dark-grey, 8%);

		.event-card__cta {
			transform: translateX(0.5rem);
		}
	}
}

.hero__card:hover {
	.event-card__cta {
		transform: translateX(0.5rem);
	}
}

.event-card__link {
	display: grid;
	grid-gap: 1rem;
	min-height: 24rem;
	padding: 1.5rem;
	color: inherit;
	text-decoration: none;
}

.event-card.with-image .event-card__link {
	grid-template: 1fr 1fr / 1fr;
}

.event-card__image {
	order: 1;
	height: 0;
	padding-bottom: 80%;
	background: $light-grey;
	background-size: cover;
	background-position: center;
}

.event-card__copy {
	order: 2;
	min-height: 21rem;
	display: grid;
	grid-template: 1fr 1fr / auto;
}

.event-card__info {
	display: flex;
	flex-flow: row wrap;
	border-top: 1px solid #535353;

	> p {
		width: 100%;
		padding-bottom: 0;
	}

	> .event-card__cta {
		padding-bottom: 1rem;
		display: block;
		align-self: flex-end;
		transition: transform .3s ease-out;
	}
}

.event-card__hed {
	@extend .typo--section-title;
}


@include breakpoint($tablet-portrait) {
	.event-cards {
		grid-template-columns: 1fr 1fr;
	}

	.event-card.with-image {
		grid-column: span 2;

		> .event-card__link {
			grid-template: auto / 1fr 1fr;
			grid-gap: 1.5rem;
		}
	}

	.event-card__image {
		margin: 0;
		height: 100%;
		padding-bottom: 0;
	}

	.event-card__image,
	.event-card__copy {
		order: initial;
	}
}

@include breakpoint($tablet-landscape) {
	.upcoming-events {
		// margin-top: -24rem;
	}

	.hero--event {
		.hero__image {
			right: calc(33.333% - 0.75rem);
			// right: 0;
		}

		.hero__card {
			position: absolute;
			top: 0rem; //3rem
			right: 0rem;
			bottom: 0rem;
			left: calc(66.667% + 0.75rem);
		}

		.event-card__copy {
			height: 100%;
		}
	}

	.event-cards {
		grid-template: auto auto / 1fr 1fr 1fr;
	}

	.event-card__link {
		min-height: 32rem;
	}

	.event-card__image {
		grid-column: 2/3;
	}

	.event-card__copy {
		min-height: auto;
	}
}

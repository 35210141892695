//
// Global & Mobile Styles
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––

.about-page {
	.site-nav__link.active {
		border-color: $brown;
	}

	.page-banner {
		background-color: $brown;

		&::after {
			height: 12rem;
			background-color: $brown;
		}
		// @include breakpoint (max-width $tablet-portrait - 1) {
		// 	height: 28rem;
		// }
	}
}

.about-us {
	padding-bottom: 6rem;
}

// .page-banner + .about-us {
// 	@include breakpoint (max-width $tablet-portrait - 1) {
// 		margin-top: -11rem;
// 	}
// }

.about-us__intro {
	font-weight: 400;
	line-height: 2.5rem;
	padding-top: 2rem;
	padding-bottom: 1rem;
}

.our-programs {
	background-color: $light-grey;
	padding-bottom: 6rem;
}

.programs__link-list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1.5rem;

	a {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		// margin-top: 3rem;
		// margin-left: 15%;
		display: block;
		border: 1px solid $dark-grey;
		border-radius: 50%;
		text-align: center;
		background-color: transparent;
		transition: all .2s;

		&:hover {
			background-color: $dark-grey;

			span {
				color: #fff;
			}
		}

		span {
			position: absolute;
			top: 55%;
			left: 0;
			right: 0;
			display: block;
			transform: translateY(-50%) scale(0.9);
			text-decoration: none;
			color: $dark-grey;
			transition: all .2s;
		}
	}
}

.cta {
	background-color: $brown;
	padding: 3rem 0;
}

.cta__copy {
	display: inline;
}

.cta__link {
	display: inline-block;
	font-family: $worksans;
	font-weight: 400;
	transform: translateX(0);
	transition: transform .3s ease-out;
	border-bottom: 1px solid lighten($dark-grey, 50%);

	&:hover {
		color: $dark-grey;
		transform: translateX(1rem);
	}
}

.our-history {
	background: $dark-grey;
	color: #fff;
}

.hcaef-stats {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-top: 6rem;
}

.hcaef-stat {
	padding: 4rem 0 2rem;
	border-top: 1px solid #535353;
	display: grid;
	grid-template: auto / 2fr 1fr;
	grid-gap: 1rem;

	&:last-child {
		border-bottom: 1px solid #535353;
	}
}

.hcaef-stat__num {
	text-align: right;
	font-family: $worksans;
	font-size: 8rem;
	line-height: 6rem;
	letter-spacing: -1px;
	padding-top: 0;
}

.hcaef-stat__copy {
	margin-top: 3px; // this is weird — fix baseline alignment?
}

.historical-highlights {
	padding-top: 8rem;
}

.historical-highlight {
	margin-top: 1rem;
	margin-bottom: 4rem;
}

.historical-highlight__image {
	width: 100%;
	height: 0;
	padding-bottom: 66.67%;
	background-color: $light-grey;
	background-size: cover;
	background-position: center;
}

.contact {
	background-color: $brown;
}

.board-members {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 1.5rem;
	list-style: none;
	padding: 0;
	padding-top: 2rem;
	padding-bottom: 2rem;
	margin: 0;
}

.board-member {
	text-align: center;
	margin-bottom: 3rem;
}

.board-member__image {
	width: 100%;
	height: 0;
	padding-bottom: 100%;
	background-color: transparent;
	margin-bottom: 2rem;
	background-size: cover;
	background-position: center;

	img {
		left: 0;
		border-radius: 50%;
	}
}

.other-contact-info {
	position: relative;
	border-top: 1px solid rgba(0, 0, 0, 0.2);

}

.other-members {
	list-style: none;
	padding: 0;
	margin: 2rem 0 4rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1.5rem;
}

.other-members + p {
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	padding-top: 2rem;
}

.hcaef-contact-info {
	font-style: normal;
	padding-top: 2rem;
}


//
// Tablet Portrait Styles
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
@include breakpoint($tablet-portrait) {
	.about-page {
		.page-banner {
			&::after {
				height: 24rem;
			}
		}
	}

	.programs__link-list {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	.hcaef-stat {
		grid-template: auto / 4fr 1fr;
	}

	.historical-highlights {
		display: grid;
		grid-template: auto auto / 1fr 1fr;
		grid-column-gap: 3rem;

		> h3 {
			grid-column: 1 / span 2;
		}
	}

	.board-members {
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 3rem;
	}
}


//
// Tablet Landscape Styles
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
@include breakpoint($tablet-landscape) {
	.about-us {
		padding-bottom: 6rem;

		> .container {
			display: grid;
			grid-template-rows: auto auto;
			grid-template-columns: 1fr 1fr;
			grid-gap: 3rem;
		}
	}

	.about-us__intro {
		padding-top: 0;
		// font-weight: 400;
		// line-height: 3.5rem;
		// @include align-to-baseline( 3rem, 3.5rem, $volkhov-cap-height );
	}

	.programs__link-list {
		grid-template-columns: 1fr 1fr 1fr;

		li:nth-child(4) {
			display: none;
		}
	}

	.our-history {
		padding-bottom: 8rem;

		.container {
			display: grid;
			grid-template-rows: auto;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
			grid-column-gap: 3rem;
		}

		.container.historical-highlights {
			grid-template-columns: 1fr 1fr;
		}
	}

	.our-history__copy {
		grid-column: 1 / span 4;
	}

	.hcaef-stats {
		margin-top: 7rem;
		grid-column: 5 / span 4;
	}

	.hcaef-stat {
		grid-template: auto / 3fr 1fr;
	}

	// .hcaef-stats {
	// 	list-style: none;
	// 	padding: 0;
	// 	padding-left: calc(((100% - (3rem * 5)) / 6) + 3rem);
	// 	margin: 0;
	// 	margin-top: 8rem;
	// }
	//
	// .hcaef-stat {
	// 	overflow: hidden;
	// 	width: 100%;
	// 	padding: 5rem 0 3rem;
	// 	border-top: 1px solid #535353;
	// 	height: 14rem;
	//
	// 	&:last-child {
	// 		border-bottom: 1px solid #535353;
	// 	}
	// }
	//
	// .hcaef-stat__num {
	// 	float: left;
	// 	width: calc(100% - 14rem);
	// 	text-align: right;
	// 	font-family: $worksans;
	// 	font-size: 8rem;
	// 	line-height: 6rem;
	// 	letter-spacing: -1px;
	// 	padding-top: 0;
	// }
	//
	// .hcaef-stat__copy {
	// 	float: left;
	// 	width: 10rem;
	// 	margin-right: 2rem;
	// 	margin-left: 2rem;
	// 	margin-top: 3px; // this is weird — fix baseline alignment?
	// }

	// .historical-highlights {
	// 	display: grid;
	// 	grid-template-rows: auto auto;
	// 	grid-template-columns: 1fr 1fr;
	// 	grid-column-gap: 3rem;
	// 	padding-top: 12rem;
	// }
	//
	// .historical-highlights__title {
	// 	grid-column: 1/-1;
	// }
	//
	// .historical-highlight {
	// 	margin-top: 1rem;
	// }
	//
	// .historical-highlight__image {
	// 	width: 100%;
	// 	height: 0;
	// 	padding-bottom: 66.667%;
	// 	background-color: $light-grey;
	// 	background-size: cover;
	// 	background-position: center;
	// }

	.contact {
		background-color: $brown;

		.container {
			display: grid;
			grid-template-rows: auto auto;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
			grid-column-gap: 3rem;
		}

		.page-section-title {
			grid-column: 1/-1;
		}
	}

	.board-members {
		grid-column: 1/9;
		display: grid;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 3rem;
		list-style: none;
		padding: 0;
		padding-top: 3rem;
		padding-bottom: 3rem;
		margin: 0;
	}

	.board-member {
		text-align: center;
		margin-bottom: 3rem;
	}

	.board-member__image {
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		background-color: transparent;
		margin-bottom: 2rem;
		background-size: cover;
		background-position: center;
	}

	.other-contact-info {
		grid-column: 10/13;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: -1.5rem;
			width: 1px;
			background-color: rgba(0, 0, 0, 0.2);
			display: block;
			mix-blend-mode: multiply;
		}
	}

	.other-members {
		list-style: none;
		padding: 0;
		margin: 3rem 0 12rem 0;
		display: block;

		li {
			margin-bottom: 2rem;
		}
	}

	.hcaef-contact-info {
		font-style: normal;
		padding-top: 2rem;
	}
}


//
// Desktop Styles
//–––––––––––––––––––––––––––————————————————————————————–––––––––––––––––––––––
@include breakpoint($desktop) {
	.our-programs {
		background-color: $light-grey;
		padding-bottom: 8rem;

		.container {
			display: grid;
			grid-template-rows: auto auto;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 3rem;
		}
	}

	.programs__link-list {
		list-style: none;
		padding: 0;
		margin: 0;
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 1.5rem;

		li:nth-child(4) {
			display: none;
		}

		a {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			margin-top: 4rem;
			display: block;
			border: 1px solid $dark-grey;
			border-radius: 50%;
			text-align: center;
			background-color: transparent;
			transition: all .2s;

			&:hover {
				background-color: $dark-grey;

				span {
					color: #fff;
				}
			}

			span {
				position: absolute;
				top: 50%;
				left: 5%;
				right: 5%;
				display: block;
				transform: translateY(-50%);
				text-decoration: none;
				color: $dark-grey;
				transition: all .2s;
			}
		}
	}

	.hcaef-stats {
		margin-top: 6rem;
		grid-column: 6 / span 3;
	}
}

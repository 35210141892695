.news-page--secondary {
	background-color: #f8f8f8;
}

.article {
	padding-bottom: 4rem;
}

.back-from-article {
	display: inline-block;
	padding-top: 2rem;
}

.article-category {
	text-transform: uppercase;
	text-decoration: underline;
	font-size: 1.333rem;
	display: initial;
	padding: 0;
	padding-bottom: 2rem !important;
}

.article-header {
	display: flex;
	flex-flow: row wrap;
	padding-top: 3rem;
}

.article-hed {
	order: 1;
}

.article-dek {
	order: 2;
	font-weight: 300;
	padding-top: 1rem;
	width: 100%;
}

.article-social-share {
	order: 3;
	padding: 2rem 0 5rem;
}

.article-social-share > a {
	position: relative;
	display: inline-block;
	margin-right: 0.5rem;
	width: 4rem;
	height: 4rem;
	border: 1px solid $dark-grey;
	border-radius: 50%;
	text-align: center;
	transition: opacity 0.2s ease-out;

	> svg {
		display: inline-block;
		position: absolute;
		left: 0;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
	}

	&:hover {
		opacity: 0.6;
	}
}

.article-tags {
	// display: none; // hidden until functionality is fully built
	margin: -2rem 0 2rem 0;
}

.article-tag {
	display: inline-block;
	padding: 0.25rem 0.5rem;
	color: lighten($dark-grey, 25%);
	border: 1px solid lighten($dark-grey, 50%);
	border-radius: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	text-transform: uppercase;
	font-size: 1.333rem;
}

.article-lead-image {
	margin-bottom: 3rem;

	img {
		display: block;
		width: 100%;
		min-height: 100px;
		background: $light-grey;
		border: none;
		outline: none;
	}
}

.image-caption {
	color: lighten($dark-grey, 50%);
	padding-top: 0;
}

.article-byline-date {
	font-weight: bold;
	padding-bottom: 2rem;
}

.article-body {
	h1 {
		font-family: $worksans;
		font-size: 2.5rem;
		line-height: 3rem;
		letter-spacing: -0.0025em;
		@include align-to-baseline( 2.5rem, 3rem, $worksans-cap-height );
		padding: 2rem 0;

		&:first-child {
			padding-top: 0;
		}
	}

	h2 {
		font-family: $worksans;
		font-size: 2rem;
		line-height: 3rem;
		letter-spacing: -0.0025em;
		@include align-to-baseline( 2rem, 3rem, $worksans-cap-height );
		padding: 2rem 0;

		&:first-child {
			padding-top: 0;
		}
	}

	h3 {
		font-family: $worksans;
		font-size: 1.5rem;
		line-height: 2rem;
		letter-spacing: -0.0025em;
		text-transform: uppercase;
		@include align-to-baseline( 1.5rem, 2rem, $worksans-cap-height );
		padding: 2rem 0;

		&:first-child {
			padding-top: 0;
		}
	}

	blockquote {
		margin: 0 4rem 2rem 0.25rem;
		padding-left: 2rem;
		border-left: 1px solid lighten($dark-grey, 60%);
	}

	ul {
		margin: 1rem 0 3rem;
		padding-left: 2.25rem;
	}

	ol {
		margin: 1rem 0 3rem;
		padding-left: 2.25rem;

		> li {
			@extend .typo--base;
		}
	}

	img, p > img {
		width: 100%;
		margin: 2rem 0 1rem;
	}
}

.related .page-section-title {
	padding-bottom: 1rem;
}

.related {
	padding-bottom: 0;
}



@include breakpoint($tablet-landscape) {
	.article {
		padding-bottom: 8rem;
	}

	.article--news > .container {
		display: grid;
		grid-template-columns: 2fr 6fr 1fr;
		grid-column-gap: 3rem;
	}

	.back-from-article {
		padding-top: 4rem;
		grid-column: 1 / span 3;
	}

	.article-header {
		grid-column: 1 / span 3;
		padding-top: 3rem;

		display: grid;
		grid-template: auto auto auto / 3fr 1fr;
		grid-column-gap: 3rem;
	}

	.article-category {
		grid-column: 1 / span 2;
		grid-row: 1 / span 1;
	}

	.article-hed {
		grid-column: 1 / span 1;
		grid-row: 2 / span 1;
	}

	.article-dek {
		grid-column: 1 / span 1;
		grid-row: 3 / span 1;
	}

	.article-social-share {
		grid-column: 2 / span 1;
		grid-row: 2 / span 2;
		text-align: right;
		padding-top: 0.5rem;
	}

	.article-social-share > a {
		margin-left: 1rem;
		margin-right: 0;
	}

	.article-tags {
		grid-column: 1 / span 3;
		margin: 4rem 0 2rem 0;
	}

	.article-lead-image {
		grid-column: 1 / span 3;
		margin-top: 6rem;
	}

	.article-tags + .article-lead-image {
		margin-top: 0;
	}

	.related .news-cards {
		display: grid;
		grid-template: auto / 1fr 1fr 1fr;
		grid-column-gap: 3rem;
	}

	.related .page-section-title {
		padding-bottom: 2rem;
	}

	.related {
		padding-bottom: 0;
	}
}


@include breakpoint($desktop) {
	.article--news > .container {
		grid-template-columns: 2fr 7fr 3fr;
	}

	.article-header {
		grid-template: auto auto auto / 8fr 1fr 3fr;
	}

	.article-category {
		grid-column: 1 / span 3;
		grid-row: 1 / span 1;
	}

	.article-hed {
		grid-column: 1 / span 1;
		grid-row: 2 / span 1;
	}

	.article-dek {
		grid-column: 1 / span 1;
		grid-row: 3 / span 1;
	}

	.article-social-share {
		grid-column: 3 / span 1;
		grid-row: 2 / span 2;
		text-align: right;
		padding-top: 0.5rem;
	}

	.article-tags {
		grid-column: 1 / span 3;
		margin: 6rem 0 2rem 0;
	}

	.article-lead-image {
		margin-bottom: 4rem;
	}
}
